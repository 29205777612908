import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Localize from "../components/localization/intl-provider.component";
const NotFoundPage = (props) => (
  <Localize {...props}>
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  </Localize>
)

export default NotFoundPage
